import React, {useState, useLayoutEffect} from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Content, { HTMLContent } from "../components/content";
import SEO from "../components/seo";
import SetLang from "../components/setLang";
import { Container } from "../Elements/Container";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import { ContactWidget } from "../components/ContactWidget";
import { ContactPopUp } from "../components/contactPopUp";


const StyledPage = styled.section`
  @media(max-width: 1024px) {
    margin-top: 3rem;
  }
  * {
    color: #555;
  }
  padding: ${rhythm(1)} 0 ${rhythm(2)};
  h1 {
    font-family: "Bebas Neue Bold";
  }
  h1,
  h2,
  h3 {
    color: #333;
    margin-top: ${rhythm(2)};
    margin-bottom: 1rem;
  }
  a {
    word-break: break-all;
    color: #91c508;
  }
`;

export const LegalPageTemplate = ({ content, contentComponent }) => {
  const PostContent = contentComponent || Content;

  return (
    <StyledPage>
      <Container>
        <PostContent content={content}/>
      </Container>
    </StyledPage>
  );
};

const LegalPage = ({ data }) => {

  const [isContactWidgetOpen, setIsContactWidgetOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait,setIsPortrait] = useState(false);

  const changeOpenWidget = () => {
    /*if(isMobile){
      if(isPortrait){
        setIsContactWidgetOpen(!isContactWidgetOpen);
      }
    }else{
      setIsContactWidgetOpen(!isContactWidgetOpen);
    } */
    setIsContactWidgetOpen(!isContactWidgetOpen);
  };

  useLayoutEffect(() => {

    if(typeof window !== "undefined"){
      
      const updateSize = () => {

          let width = window.screen.width;
          let height = window.screen.height;

          if(width < 1026){
            setIsMobile(true);
          }else{
            setIsMobile(false);
          }

          if(height > width){
            setIsPortrait(true);
          }else{
            setIsPortrait(false);
          }
      };

      window.addEventListener("resize",updateSize);
    }
  });

  const {
    language,
    title,
    redirects,
    keywords,
    description,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO
        title={title}
        lang={language}
        description={description}
        keywords={keywords}
      />
      <SetLang language={language} link={redirects} />
      <LegalPageTemplate
        content={data.markdownRemark.html}
        contentComponent={HTMLContent}
      />

      <div>
        <ContactWidget changeOpenWidget={changeOpenWidget}/>

        {isContactWidgetOpen == true ? (
          <>
           <ContactPopUp changeOpenWidget={changeOpenWidget} />
          </>
        ) : <></>}

      </div>
      
    </Layout>
  );
};

export default LegalPage;

export const pageQuery = graphql`
  query advisePageyID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        keywords
        tags
        redirects
        language
      }
    }
  }
`;
